import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
// import { PDFViewer } from "@react-pdf/renderer";
import PDF from "./document.pdf";
import "./SAC.css";
import Button from "react-bootstrap/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function SACReader() {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
  }

  const pages = [];
  for (let i = 1; i <= numPages.numPages; i++) {
    pages.push(
      <div key={i}>
        <Document
          pageMode={"fullScreen"}
          file={PDF}
          // onLoadSuccess={onDocumentLoadSuccess}
          renderMode={"svg"}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Page
            pageNumber={i}
            dpi={300}
            height={2000}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    );
  }

  var matricula = "6833214";
  const matriculaScreen = [];
  for (let i = 1; i <= 20; i++) {
    if (i % 2 === 0) {
      matriculaScreen.push(
        <div key={i} className={"fons fonsProtegit protegit" + i}>
          {matricula}
        </div>
      );
    } else {
      matriculaScreen.push(
        <div key={i} className={"fons fonsProtegit protegit" + i}>
          <img
            src="/logoRenfe.svg"
            alt="renfe logo"
            className="logoRenfeProtegit"
          />
        </div>
      );
    }
  }

  const navigateBout = () => {
    // 👇️ navigate to /
    navigate("#final");
    console.log("final");
  };

  return (
    <div className="contenidorLector unselectable">
      {/*Boto per anar al final de la pàgina*/}
      <Button variant="secondary" href="#final">
        Aller au bout du document
      </Button>
      <Document
        pageMode={"fullScreen"}
        file={PDF}
        onLoadSuccess={onDocumentLoadSuccess}
      ></Document>
      {pages}
      <div id="final"></div>
      {matriculaScreen}
    </div>
  );
}

export default SACReader;
