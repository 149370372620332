import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../../Api";
import { useRef } from "react";

function AVITrack() {
  const [coordinates, setCoordinates] = useState(null);
  const [horari, setHorari] = useState(null);

  const navigate = useNavigate();

  // Crear un array amb les variables data i tren
  const params = useParams();
  let data = params.data;
  let numero_tren = params.tren;

  const componentRef = useRef();

  useEffect(() => {
    watchCoordinates();
    Api.get("/horari/?data=" + data + "&tren=" + numero_tren)
      .then((response) => {
        setHorari(response.data);
        // setPantalla(2);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function watchCoordinates() {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.watchPosition(
      (position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  return (
    <div>
      {coordinates ? (
        <div>
          Latitude: {coordinates.latitude}
          <br />
          Longitude: {coordinates.longitude}
          <br />
          Speed: {coordinates.speed}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default AVITrack;
