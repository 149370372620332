import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";

function FormulariAcces(props) {
  const [contrasenya, setContrasenya] = useState(null);
  const [errorContrasenya, setErrorContrasenya] = useState(false);

  function handleChange(e) {
    setContrasenya(e.target.value);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    console.log(contrasenya);
    // if (form.checkValidity() === false) {
    //
    //   event.stopPropagation();
    // }
    if (contrasenya === "RenfeAVI23") {
      // if (contrasenya === "TrenitaliaFrance23") {
      setErrorContrasenya(false);
      //Crea una cookie d'1 dia de durada que permeti l'accés a la pàgina
      var expires = "";
      var date = new Date();
      date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
      document.cookie = "autoritzat=true" + expires + "; path=/";
      props.canviAutoritzat(true);
    } else {
      setErrorContrasenya(true);
    }
  };

  React.useEffect(() => {
    //Si la cookie existeix, es canvia l'estat de l'autorització
    if (document.cookie.indexOf("autoritzat=true") !== -1) {
      props.canviAutoritzat(true);
    }
  });

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="2">
            <Form.Control
              type="password"
              id="contrasenyaAcces"
              aria-describedby="passwordHelpBlock"
              placeholder="Mot de passe"
              onChange={handleChange}
            />
          </Col>
          <Col md="2">
            <Button variant="primary" type="submit">
              Entrer
            </Button>
          </Col>
        </Row>

        {/* <Button variant="secondary" onClick={props.canviAutoritzat}>
          {props.autoritzat ? "Accedir" : "No accedir"}
        </Button> */}
      </Form>
      {errorContrasenya && (
        <Alert variant="danger" className="missatgeError">
          Le mot de passe est incorrect. Veuillez réessayer.
        </Alert>
      )}
    </div>
  );
}

export default FormulariAcces;
