import "./css/Renfe.css"; //RENFE
// import "./css/TrenItalia.css"; //TRENITALIA
import "./css/App.css";

import Top from "./estructura/Top";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Outlet } from "react-router-dom";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Loading from "./estructura/Loading";
import Footer from "./estructura/Footer";
import { useState } from "react";
import FormulariAcces from "./estructura/FormulariAcces";

const isProduction = process.env.NODE_ENV === "production";

function App() {
  const [autoritzat, setAutoritzat] = useState(false);

  function canviAutoritzat(e) {
    autoritzat ? setAutoritzat(false) : setAutoritzat(true);
  }

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <header className="App-header">
          <Top />
        </header>
        <div className="App-body">
          <Container>
            <Row>
              <Col>
                {autoritzat && <Outlet />}
                {!autoritzat && (
                  <FormulariAcces
                    canviAutoritzat={canviAutoritzat}
                    autoritzat
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="App-footer">
          <Footer />
        </footer>
      </div>
    </CacheBuster>
  );
}

export default App;
