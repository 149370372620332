import Parada from "./Parada";
import React from "react";
import VelocitatsMitjanes from "./horari/VelocitatsMitjanes";
import Notes from "./horari/Notes";
import Alert from "react-bootstrap/Alert";

function Horari(props) {
  const horari = props.horari;
  var veurePL = props.veurePL;
  var veurePK = props.veurePK;
  var veureNotes = props.veureNotes;
  var mostrarPL = "mostrarPL";
  if (!veurePL) mostrarPL = "noMostrarPL";

  const numTotalParades = horari.parades.length;
  var numComentari = -1;

  const Taula = (props) => {
    return React.createElement("section", props, props.children);
  };
  //
  React.useEffect(() => {
    if (horari)
      document.title =
        horari.numero_tren + " (" + horari.data + ") - Fiche Horaire";
  }, []);

  function setNumComentari(num) {
    numComentari = num;
  }

  function retornaNumComentari() {
    console.log(numComentari);
    numComentari++;
    return numComentari;
  }

  function comptaRemarques(remarques) {
    //Compta quantes r apareixen a la variable remarques i retorna-la
    var numRemarques = remarques
      .split("")
      .filter((char) => char === "r").length;
    console.log("Remarques: " + numRemarques);

    return numRemarques;
  }

  return (
    <div className={"itinerari " + mostrarPL}>
      <div className="dadesItinerari">
        <div className="numeroTren">{horari.numero_tren}</div>
        <div className="liniaData">
          <span className="data">{horari.data} </span>
        </div>
        <div className="textItinerari">
          de {horari.origen} à {horari.desti}
        </div>
        <div className="liniaTipusTren">
          Code de composition:{" "}
          <span className="tipusTren">{horari.tipus_tren}</span>
        </div>
        <div className="logoRenfe">
          <img src="/logoRenfe.svg" alt="renfe logo" />
          {/* <img src="/logoTrenitalia.svg" alt="renfe logo" /> */}
        </div>
      </div>
      {horari.exploradora && (
        <Alert variant="warning">
          <b>Attention!</b> Sur une partie du traject, ce train doit effectuer
          la reconnaissance de la ligne.
        </Alert>
      )}
      <div className="fullParades">
        <div className="divTable">
          {/* <div class="divTableHeading">
            <div class="divTableRow">
              <div class="divTableHead">head1</div>
            </div>
          </div> */}
          <div className="divTableBody">
            <div className="divTableRow topTaula">
              {veurePK && <div className="divTableCell">PK</div>}
              <div className="divTableCell columnaEstacio">Gare</div>
              <div className="divTableCell">Poste</div>
              <div className="divTableCell">Voie</div>
              <div className="divTableCell">H.A.</div>
              <div className="divTableCell">H.P.</div>
              <div className="divTableCell">H.S.</div>
              <div className="divTableCell">Type</div>
              <div className="divTableCell">Re.</div>
            </div>
            {horari.parades.map((parada, index) => (
              <Taula className="taulaElements">
                <div className="parada divTableRow" key={"parada_" + index}>
                  <Parada
                    parada={parada}
                    paradaNum={index}
                    numTotalParades={numTotalParades}
                    veurePK={veurePK}
                  />
                </div>
                {parada.remarca && comptaRemarques(parada.remarca) === 1 && (
                  <div className=" divTableRow">
                    {veurePK && <div className="divTableCell"></div>}
                    <div className="remarcaTaula divTableCell">
                      {horari.comentaris[retornaNumComentari()]}
                    </div>
                  </div>
                )}
                {parada.remarca && comptaRemarques(parada.remarca) === 2 && (
                  <div className=" divTableRow">
                    {veurePK && <div className="divTableCell"></div>}
                    <div className="remarcaTaula divTableCell">
                      <div>{horari.comentaris[retornaNumComentari()]}</div>
                      <div>{horari.comentaris[retornaNumComentari()]}</div>
                    </div>
                  </div>
                )}
                {parada.remarca && comptaRemarques(parada.remarca) === 3 && (
                  <div className=" divTableRow">
                    {veurePK && <div className="divTableCell"></div>}
                    <div className="remarcaTaula divTableCell">
                      <div>{horari.comentaris[retornaNumComentari()]}</div>
                      <div>{horari.comentaris[retornaNumComentari()]}</div>
                      <div>{horari.comentaris[retornaNumComentari()]}</div>
                    </div>
                  </div>
                )}
              </Taula>
            ))}
          </div>
        </div>
      </div>

      {horari.comentaris.length > 0 && (
        <div className="observacions">
          <h4>Remarques</h4>
          {horari.comentaris.map((comentari, index) => (
            <div className="observacio" key={"observacio_" + index}>
              {comentari}
            </div>
          ))}
        </div>
      )}

      {horari.velocitats_mitjanes.length > 0 && (
        <VelocitatsMitjanes velocitats_mitjanes={horari.velocitats_mitjanes} />
      )}
      {veureNotes && <Notes />}
    </div>
  );
}

export default Horari;
