function Parada(props) {
  const parada = props.parada;
  var trenPara = "noPara";
  var trenParadaEspecial = "";
  if (parada.hora_sortida && !parada.tipus_parada) trenPara = "para";
  if (props.numTotalParades === props.paradaNum + 1 && parada.poste === "BV")
    trenPara = "ultima";
  if (props.paradaNum === 0 && parada.poste === "BV" && !parada.tipus_parada) {
    parada.hora_sortida = parada.hora_pas;
    trenPara = "para";
  }
  if (trenPara === "ultima" && parada.poste === "BV") {
    parada.hora_arribada = parada.hora_pas;
    trenPara = "para ultima";
  }
  if (parada.tipus_parada) {
    trenParadaEspecial = "trenParadaEspecial";
  }

  var tipusEstacio = "PL";
  if (
    parada.poste === "BV" ||
    parada.poste === "00" ||
    parada.poste === "PR" ||
    parada.poste === "BL" ||
    parada.poste === "BM" ||
    parada.poste === "CV" ||
    parada.poste === "RL" ||
    parada.poste === "47" ||
    parada.poste === "48" ||
    parada.poste === "49" ||
    parada.poste === "50" ||
    parada.poste === "51" ||
    parada.poste === "52" ||
    parada.poste === "53" ||
    parada.poste === "54" ||
    parada.hora_sortida ||
    parada.remarca ||
    props.paradaNum === 0 ||
    props.numTotalParades === props.paradaNum + 1
  )
    tipusEstacio = "gare";

  return (
    <span className={trenPara + " " + tipusEstacio + " " + trenParadaEspecial}>
      {props.veurePK && (
        <div className="pk divTableCell">
          {parada.pk && <span>{parada.pk}</span>}
        </div>
      )}
      <div className="nomParada divTableCell">{parada.estacio}</div>
      <div className="poste divTableCell">{parada.poste}</div>
      <div className="via divTableCell">{parada.via}</div>
      <div className="horaArribada divTableCell">
        {parada.hora_arribada && <span> {parada.hora_arribada}</span>}
      </div>
      <div className="horaPas divTableCell">
        {parada.hora_pas && <span> {parada.hora_pas}</span>}
      </div>
      <div className="horaSortida divTableCell">
        {parada.hora_sortida && <span>{parada.hora_sortida}</span>}
      </div>
      <div className="tipusParada divTableCell">
        {parada.tipus_parada && <span> {parada.tipus_parada}</span>}
      </div>
      <div className="taulaRemarca divTableCell">
        {parada.remarca && <span className="remarca"> {parada.remarca}</span>}
      </div>
    </span>
  );
}

export default Parada;
